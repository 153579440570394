import Header from '../Header'

export default function Traveling() {
    return (
        <>
            <Header />
            <h2>Traveling Page</h2>
            <div>I love travel</div>
        </>
    )
}